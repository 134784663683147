<template>
    <div>     
        <!-- <VueCal
        class="vuecal--rounded-theme vuecal--green-theme"
        style="height: 600px"
        :disable-views="['years', 'year']"
        active-view="day"
        today-button 
        locale="pt-br"
        :events="events"
        :on-event-click="onEventClick"
        >
        </VueCal> -->
        <FullCalendar :options="calendarOptions">
            <!-- <template v-slot:eventContent='arg'>
                <b>{{ arg.event.title }} teste</b>
            </template> -->
            <template #eventContent="{ event }">
                <div class="hstack w-fit text-white" :style="`background-color: ${event.backgroundColor}; border-radius: .5em; border-top-left-radius: 2em; border-bottom-left-radius: 2em`">
                    <div>
                        <b-avatar class="bg-secondary text-white" size="3rem" :src="event.extendedProps.photoURL"></b-avatar>
                    </div>
                    <div class="px-2">
                        <div>
                            {{ event.extendedProps.hora }}
                        </div>
                        <div class="box-clamp-1">{{ event.title }}</div>
                    </div>
                </div>
            </template>
        </FullCalendar>
        
       <!-- <p>Channel: {{ operator.channel }}</p>
       <p>Channel iD: {{ operator.channel._id }}</p>
       <p>Channel Type: {{ operator.channel.apiType }}</p>
       <p>Operator id: {{ operator._id }}</p>
       <p>templates: {{ templates }}</p>
       <p>templates ID: {{ templates[0].id }}</p>
       <p>templates: {{ templates[0] }}</p> -->
        <!-- <b-modal v-model="showDialogx" >
            <template #modal-title>
                    <span>{{ selectedEvent.title }}</span>
                </template>
            <div>
                <div>
                
                <span>{{ selectedEvent.title }}</span>
                
                
                <strong>{{ selectedEvent.start && selectedEvent.start.format('DD/MM/YYYY') }}</strong>
                </div>
                <div>
                <p v-html="selectedEvent.contentFull"/>
                <strong>Event details:</strong>
                <ul>
                    <li>Event starts at: {{ selectedEvent.start && selectedEvent.start.formatTime() }}</li>
                    <li>Event ends at: {{ selectedEvent.end && selectedEvent.end.formatTime() }}</li>
                </ul>
                </div>
            </div>
        </b-modal> -->
        <b-modal body-class="p-0" header-class="border-grey" v-model="showDialog" size="lg" @close="onCloseDialog" hide-footer>
            <template #modal-title>
                <div class="hstack gap-2">
                    <b-img  class="darkenTextOnHover" style="width: 1.5rem; height: 1.5rem;" :src="require('../assets/images/scheduleIcons/notebook.png')"></b-img>
                    <span>Atualizar Agendamento</span>
                </div>
            </template>
            <div>
                <b-row class="m-0 px-4 py-3 border-bottom-grey">
                    <b-col cols="2" class="p-0 d-flex align-items-center flex-basis-content" >
                        <b-avatar class="bg-secondary text-white" size="3rem" :src="selectedEvent.photoURL"></b-avatar>
                    </b-col>
                    <b-col class="d-flex align-items-center">
                        <div class="fw-semibold text-secondary text-truncate position-relative w-min mw-100">
                            <span>
                                {{ selectedEvent.name }}
                                <span v-if="selectedEvent.state">
                                    {{ " " }} de {{ selectedEvent.state }}
                                </span>
                                &#8205; &#8205; &#8205; &#8205;
                            </span>                            
                        </div>
                    </b-col>
                </b-row>
                <b-row class="m-0 gap-3 py-3 px-4">                   
                        <b-col class="p-0">
                            <b-form-group class="fs-6 text-secondary" label="Descrição:">
                                <b-form-textarea
                                    class="border-grey rounded"
                                    v-model="selectedEvent.description"
                                    placeholder="Descrição"
                                    rows="10"
                                    no-resize
                                    ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col class="p-0 d-flex flex-column gap-2">
                            <b-row class="m-0 gap-3">
                                <b-col class="p-0 d-flex flex-column gap-2">
                                    <b-form-group label-class="fs-6 text-secondary" label="Data:">
                                        <b-form-input class="border-grey rounded" style="height: 2rem" type="date" size="sm" v-model="selectedEvent.data" locale="pt"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label-class="fs-6 text-secondary" label="Hora:">
                                        <b-form-input class="border-grey rounded" style="height: 2rem" type="time" size="sm" v-model="selectedEvent.hora" locale="pt"></b-form-input>                                        
                                    </b-form-group>
                                </b-col>
                                <b-col class="p-0 d-flex flex-column gap-2">
                                    <b-form-group label-class="fs-6 text-secondary" label="Minutos antes:">
                                        <b-form-spinbutton class="border-grey rounded" style="height: 2rem" v-model="selectedEvent.timeToNotify" min="0" max="60"></b-form-spinbutton>
                                    </b-form-group>
                                    <b-form-group label-class="fs-6 text-secondary" label="Etiqueta:">
                                        <b-form-input class="border-grey rounded" style="height: 2rem" type="color" size="sm" v-model="selectedEvent.color" ></b-form-input>                                        
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <span>
                                <b-form-checkbox
                                    class="hstack gap-2 text-secondary small-text"
                                    v-model="selectedEvent.notify"
                                >
                                    Notificar Contato?
                                </b-form-checkbox>
                            </span>
                            <b-form-group label-class="fs-6 text-secondary m-0" label="Mensagem:" v-if="selectedEvent.notify && operator.channel.apiType == 'business'">
                                <b-form-textarea
                                    class="border-grey"
                                    v-model="selectedEvent.reminderMessage"
                                    placeholder="Mensagem"
                                    rows="3"
                                    no-resize
                                ></b-form-textarea>
                            </b-form-group>
                            <b-form-group label-class="fs-6 text-secondary m-0 w-100" label="Template HSM:" v-else-if="selectedEvent.notify">
                                <b-form-select class="w-100 rounded text-secondary p-2 border-grey" v-model="selectedEvent.reminderMessage">
                                    <option :value="item" v-for="item in templates" :key="item.id" >{{ item.name }}</option>
                                </b-form-select>
                                <!-- <Whatsapp class="mt-3" :messages="[{ type: 'template', body: saveCalendar.template, fromMe: true }]" :height="'50vh'" :mediaURL="mediaURL" v-if="selectedTemplate.template" /> -->
                            </b-form-group>
                        </b-col>                   
                </b-row>
                <div class="hstack justify-content-between gap-3 pb-3 px-4">
                    <b-button class="btn-red border-0 px-3" @click="deleteCalendarOP">
                        Deletar
                        <b-icon class=" darkenTextOnHover" font-scale="1.25" icon="trash"></b-icon>                   
                    </b-button>
                    <div class="hstack gap-3">
                        <b-button class="bg-transparent border-grey text-secondary px-3" @click="showDialog=!showDialog">Cancelar</b-button>
                        <b-button class="bg-green3 border-green3 px-4 d-inline-flex align-items-center" @click="updateCalendarOP()" >
                            Atualizar
                        </b-button>
                    </div>
                </div>
                
                <!-- <p v-if="clientSelected">
                    channel: {{clientSelected.channel}}
                    clientNumber: {{clientSelected.clientNumber}}
                    contactId: {{clientSelected.contactId}}
                    operatorId: {{clientSelected.operatorId}}
                    {
                        "channelId": "62d9432176c1d572f1f67079",
                        "contactId": "659da164d419aed4857d1e00",
                        "schedulingDate": "2024-02-01T15:00",
                        "notify": false,
                        "timeToNotify": null,
                        "reminderMessage": "",
                        "description": "Teste"
                    }
                </p> -->
            </div>
        </b-modal>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

//import VueCal from 'vue-cal'
import moment from 'moment'
import 'vue-cal/dist/vuecal.css'
import { getToken } from '../services/userService.js'
import api from '../services/apiService.js'
export default {
    components: {  FullCalendar},
    props:['operator','templates','emitMSG','openCreateCalendar'],
    mounted: function() {
        this.$nextTick(async function() {
            Date.prototype.addMinutos = function(minutos) {
                this.setMinutes(this.getMinutes() + minutos)
            };
            this.loadEvents()
        })
    },
    data() {
        return{
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
                initialView: 'timeGridDay',
                views: {
                    dayGridMonth: {
                        titleFormat: { month: 'long', year: 'numeric' },
                        dayHeaderFormat: { weekday: 'long' },
                    },
                    dayGrid: {
                        // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
                    },
                    timeGrid: {
                        // options apply to timeGridWeek and timeGridDay views
                        dayHeaderFormat: { day: 'numeric', weekday: 'long' },
                    },
                    week: {
                        // options apply to dayGridWeek and timeGridWeek views
                    },
                    day: {
                        // options apply to dayGridDay and timeGridDay views
                    }
                },
                headerToolbar: {
                    left: 'title',
                    center: 'timeGridDay timeGridWeek dayGridMonth',
                    right: 'prev,next,today',
                },
                buttonText:{
                    today:    `Hoje: ${new Date().toLocaleDateString('pt-BR', { month: 'short', day: 'numeric' })}`,
                    month:    'Mês',
                    week:     'Semana',
                    day:      'Dia',
                },
                locale:'pt-br',
                selectable:true,
                dateClick: this.openCreateCalendar,
                eventClick: this.handleDateClick,
                initialEvents: this.events,
                events: [],
                currentEvents: this.events,
                height: '100%',
                allDaySlot: false,
            }, 
            clientSelected:{},
            eventsAPI:{},
            selectedEvent: {e:{color: "#000"}},
            showDialog: false,
            eventsEx:[],
            events: [],
        }
    },
    methods: {
        async handleDateClick(clickInfo) {
            const event = clickInfo.event.extendedProps
            const tempOBJ = {}
            const contactResp = await api.getContact(event.e.contactId)
            tempOBJ.name = contactResp.contact.name
            tempOBJ.photoURL = contactResp.contact.photoURL
            tempOBJ.state = contactResp.contact.state           
            tempOBJ.color = event.e.color           
            tempOBJ.backgroundColor = event.e.color           
            const date = new Date(event.data)
            date.setHours(date.getHours() - 3)
            console.log({date: date.toISOString()}, date.toISOString().split('T')[1].split('-')[0])
            tempOBJ.data = date.toISOString().split('T')[0]
            tempOBJ.hora = date.toISOString().split('T')[1].split('-')[0].split('.')[0].slice(0, -3)
            this.selectedEvent = Object.assign({},event, tempOBJ);
            this.showDialog = true
        },
        async loadEvents() {
            this.eventsAPI = await api.getFullCalendar(this.operator._id)

            this.events = this.eventsAPI.operatorCalendar.map(e=>{
                const obj = {
                    start: this.formatDate(e.schedulingDate, false),
                    end: this.formatDate(e.schedulingDate, true),
                    title: e.description,
                    photoURL:'https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fperson%20(2).png?alt=media&token=e07b34b7-ff61-4d08-a376-56a884269e62',
                    icon: 'shopping_cart', // Custom attribute.
                    content: e.description,
                    contentFull: e.description, // Custom attribute.
                    class: 'leisure',
                    data: this.formatData(e.schedulingDate, true),
                    hora: this.formatHora(e.schedulingDate, true),
                    color : e.color,
                    backgroundColor : e.color,
                    description:e.description,
                    timeToNotify:e.timeToNotify,
                    notify:e.notify,
                    channelId:e.channelId,
                    contactId:e.contactId,
                    operatorId:e.operatorId,
                    reminderMessage:e.reminderMessage,
                    id:e._id,
                    _id:e._id,
                    e
                }
                return obj
            })
            this.calendarOptions.events =  this.events
            const temp = []
            for (let index = 0; index < this.events.length; index++) {
                const element = this.events[index];
                const contactResp = await api.getContact(element.contactId)                
                element.photoURL = contactResp.contact.photoURL
                temp.push(element)
            }
            this.calendarOptions.events = temp
        },
        async deleteCalendarOP() {
            this.selectedEvent.id = this.selectedEvent._id
            await api.deleteCalendar(this.selectedEvent.id,getToken())
            this.showDialog=!this.showDialog
            this.loadEvents()
            setTimeout(() => {
                this.loadEvents()
            }, 1000);
        },
        async updateCalendarOP() {
            this.selectedEvent.id = this.selectedEvent._id
            this.selectedEvent.schedulingDate = this.selectedEvent.data.split('T')[0]+'T'+this.selectedEvent.hora
            try {
                await api.updateCalendar(this.selectedEvent,getToken())
                this.showDialog=!this.showDialog
                this.loadEvents()
                setTimeout(() => {
                    this.loadEvents()
                }, 1000);
                
            } catch (error) {
                console.log('error',error)
                this.emitMSG()
                //this.$emit('msg',{success:true,text:"Verifique os campos digitados e tente novamente!"})
            }
        },
        async onEventClick (event, e) {
            e.cache = event
            const contactResp = await api.getContact(event.e.contactId)
            event.name = contactResp.contact.name
            event.photoURL = contactResp.contact.photoURL
            event.state = contactResp.contact.state
            event.hora = this.formatHora('T'+event.hora+'.',true)
            this.selectedEvent = event
            this.showDialog = true
            //console.log(contactResp)
            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation()
        },
        onCloseDialog() {
            this.selectedEvent = this.selectedEvent.e
        },
        formatDate(date,end=false,locale=0) {
            let more = new moment(date)
            
            //more = more.subtract('hour',3)
            if(locale != 0) {
                more = more.subtract('hour',locale)
            }
            if(end) {                
                more = more.add('minutes',60)
                return  moment(more.toDate()).format('YYYY-MM-DD HH:mm')                
            }
            more = moment(more.toDate()).format('YYYY-MM-DD HH:mm')
            return more
        },
        formatData(schedulingDate) {
            
            return schedulingDate.split(' ')[0]
        },//
        formatHora(schedulingDate,fix=false) {
            // let hour = schedulingDate.split('T')[1].split('.')[0]
            // if(fix) {
            //     const h = hour.split(":")[0]     
            //     const e = parseInt(h)-3
            //     hour = e+":"+hour.split(":")[1]
            // }
            const hour = new moment(schedulingDate).format('HH:mm')
            return hour
        },
        verifyPhotoURL(url) {
            if(url) {
                if(!url.includes('http'))
                    return `${this.mediaURL}avatar/${url}`
                return url
            }
        }
    }
}
</script>

<style>
    :root {
        --fc-border-color: #ddd;
        --fc-today-bg-color: #eee;
    }
    .fc .fc-toolbar.fc-header-toolbar {
        padding: 1rem 4rem 1rem 1.1rem;
        margin: 0;
    }
    .fc .fc-toolbar-title {
        color: hsl(241, 30%, 59%);
        font-size: 1.5rem;
    }
    .fc .fc-toolbar-title:first-letter {
        text-transform: uppercase;
    }
    .fc .fc-toolbar-chunk:not(:has(.fc-button-group)) button, .fc .fc-toolbar-chunk:not(:has(.fc-button-group)) button:hover {
        color: hsl(241, 30%, 59%);
        background-color: transparent;
        border: none;
        border-radius: 0;
        font-weight: 500;
        box-shadow: none !important
    }
    .fc .fc-toolbar-chunk:not(:has(.fc-button-group)) button:hover {
        border-bottom: 2px solid hsl(241, 30%, 59%);
    }
    .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
        color: hsl(241, 30%, 59%);
        background-color: transparent;
        border: none;
        border-bottom: 2px solid hsl(242deg 21% 44%);
        outline: 0;
        box-shadow: none;
    }
    .fc .fc-toolbar-chunk:has(.fc-button-group), .fc .fc-button-group {
        display: flex;
        gap: .5rem;
    }
    .fc .fc-button-group {
        display: flex;
        gap: .5rem;
    }
    .fc .fc-today-button {
        color: hsl(138.55deg 45.08% 42.16%) !important;
        background-color: transparent !important;
        border: none !important;
        font-weight: 500 !important;
        padding: 0 !important;
    }
    .fc-prev-button, .fc-next-button {
        color: #fff !important;
        background-color: hsl(138.55deg 45.08% 52.16%) !important;
        border-color: hsl(138.55deg 45.08% 52.16%) !important;
        border-radius: 50% !important;
        padding: 0rem .2rem .2rem !important;
    }
    .fc-button-group .fc-prev-button:hover, .fc-button-group .fc-next-button:hover {
        background-color: hsl(138.55deg 45.08% 42.16%) !important;
        border-color: hsl(138.55deg 45.08% 42.16%) !important;
    }
    .fc-icon {
        font-size: 1.25rem !important;
    }
    .fc-theme-standard .fc-scrollgrid {
        border: none;
    }
    .fc .fc-col-header .fc-timegrid-axis {
        background-color: hsl(244deg 62% 95%);
        border: none;
    }
    .fc .fc-col-header-cell {
        border: none;
        padding: .75rem 0;
        background-color: hsl(244deg 62% 95%);
    }
    .fc .fc-col-header-cell .fc-col-header-cell-cushion {
        color: hsl(241, 30%, 59%);
        font-weight: 400;
    }
    .fc .fc-col-header-cell .fc-col-header-cell-cushion:first-letter {
        text-transform: uppercase;
    }
    .fc a, .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
        color: hsl(241, 30%, 59%);
    }
    .fc .fc-timegrid-slot-minor {
        border: none;
    }
    .fc .fc-timegrid-event {
        display: contents;
    }
    .fc .fc-timegrid-event-harness, .fc .fc-timegrid-event {
        width: fit-content;
    }
    .fc .fc-daygrid-event {
        border-radius: 3rem;
    }
    @media(max-width: 425px) {
        .fc .fc-toolbar-title {
            font-size: 1.1rem;
        }
        .fc .fc-toolbar-chunk:not(:has(.fc-button-group)):has(.fc-timeGridDay-button) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 .5rem
        }
        .fc .fc-toolbar-chunk:not(:has(.fc-button-group)) button, .fc .fc-toolbar-chunk:not(:has(.fc-button-group)) button:hover {
            padding: 0
        }
        .fc .fc-button-group {
            gap: .5rem;
        }
        .fc .fc-today-button {
            display: none
        }
        .fc-button-group {
            align-items: center
        }
        .fc-button-group .fc-prev-button, .fc-button-group .fc-next-button {
            height: fit-content !important
        }
        .fc-col-header-cell {
            background-color: red
        }
        .fc .fc-col-header-cell .fc-col-header-cell-cushion, .fc .fc-timegrid-axis-cushion {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%
        }
    }
</style>

